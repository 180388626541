$(document).ready(function() {
	if(!document.getElementById('modal_find_my_model')) return false;
	
	// #### modal : Find my Model category scripts
	var mainselect = document.getElementById('mainCategorySelect_fmm');
	var subselect = document.getElementById('subCategorySelect_fmm');

	var modal = {
		el: document.getElementById("modal_find_my_model"),
		form: document.getElementById("findMyModelForm"),
		submit: document.getElementById("findMyModelSubmit"),
		init: function(){
			modal.addEvent();
		},
		addEvent: function(){
			$(modal.el).on({
				'show.bs.modal': function(e){
					adobeTrackEvent('find_my_model', {page_event : {find_my_model : true}});
					$(mainselect).drawAjaxOptions({
						setTarget: subselect
					});
				}
			});
			$(modal.form).on({
				submit: function(e){
					e.preventDefault();

					var param = $(this).serializeArray();
					var url = this.getAttribute('data-url');
					// var data = ajax.call(url, param, 'html');
					ajax.call(url, param, 'html', function(data){
						$(modal.el).find('.find-model-results').addClass('active').html(data).find('style').remove();
						$(modal.el).find('.find-model-default').removeClass('active');
						$(modal.el).modalUpdate();
					});
				}
			});
			$(mainselect).on({
				'change.base': function(){
					$(modal.el).find('.find-model-results').removeClass('active');
					$(modal.el).find('.find-model-default').addClass('active');
				}
			});
			$(subselect).on({
				'change.sub': function(){
					$(modal.form).submit();
				}
			});
		}
	};
	modal.init();

	//Example URL /us/support/repair-service/schedule-repair-continued?pop=true
	var parameters = new URL(document.location).searchParams;
	parameters.get("pop") == "true" ? $("#modal_find_my_model").modal("show"):null;

	$('body').on('click.seemore', '.search-area .search-layer .search-footer-area a.link-more', function(e) {
		e.preventDefault();
		var url = $(this).attr('href');
		var searchTxt = $(this).closest('form').find('.search-input input').val();
		$form = $("<form />", {id: 'tmpForm', action:url, method: 'post'});
		$form.append('<input type="hidden" name="search" value="'+xssfilter(searchTxt)+'">');
		$('body').append($form);
		$("#tmpForm").submit();
	});
});